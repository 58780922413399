.maxvh85 {
  max-height: 85vh;
  min-height: 330px;
  overflow: scroll;
}

.maxvh70 {
  max-height: 70vh;
  overflow: scroll;
}

.minh110 {
  min-height: 110px;
}

.daysinputx {
  width: 8rem;
}

.bmrhhgenunitdt tr td {
  text-align: right;
}

.bmrhhgenunitdt tr td:first-child {
  text-align: left;
}

.bmrhhgenunitdt tr td:last-child,
.bmrhhgenunitdt tr th:last-child {
  display: none;
}

.tooltip-inner {
  font-size: 0.7rem;
}

.edge-header {
  height: 200px;
}

.overlay {
  background: #e9e9e9;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  height: 100%;
  z-index: 1;
}

.spinner-center-overlay {
  position: relative;
  top: 50%;
  height: 5rem;
  width: 5rem;
  margin-left: 45%;
  margin-top: -2.5rem;
}

.tab-content .tab-pane.active,
.tab-content .tab-pane.active .tab-subsection {
  page-break-inside: avoid;
}

#instructions {
  counter-reset: incounter;
}

#instructions h5 {
  font-weight: bold;
}

#instructions h5:before {
  content: counter(incounter) ".\0000a0\0000a0";
  counter-increment: incounter;
}

#instructions h5.nocount:before {
  content: none;
  counter-increment: none;
}

.nav-tabs .nav-link {
  border-bottom: 1px solid lightgray;
}

.nav-tabs .nav-link:hover {
  border: 1px solid lightgray;
  border-bottom: 2px solid white;
}

/* .navbar-toggler-icon {
	 color: #2e7d32 !important;
} */

.navbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: #43a047;
  /*  border-bottom: 2px solid white; */
}

/*.navbar.navbar-light .navbar-nav .nav-item .dropdown .dropdown-menu .nav-link {
  border-bottom: 0px;
} */

.navbar.navbar-light
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-menu
  .nav-link
  .dropdown-item {
  color: #43a047;
}

.navbar.navbar-light .navbar-nav .nav-item .nav-link.active {
  background-color: #e8f5e9;
}

.navbar.navbar-light .navbar-nav .nav-item .nav-link.active:hover {
  background-color: #c8e6c9;
}

.navbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #2e7d32;
  background-color: #f5f5f5;
  /*  border-bottom: 2px solid #81c784; */
}

.navbar.navbar-light
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-menu
  .nav-link:hover
  .dropdown-item {
  color: #2e7d32;
  /*  border-bottom: 0px; */
}

.navbar.navbar-light
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-menu
  .nav-link
  .dropdown-item {
  border-bottom: 0px;
}

.fixed-action-btn .list-unstyled {
  width: 0;
}

.sidenavhead {
  height: 0;
}

.sidenavbtn {
  position: fixed !important;
  top: 92px;
  left: 0px;
  height: 30px;
  margin: 0;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  z-index: 998;
  opacity: 1 !important;
  transition: left 0.3s;
  -webkit-transition: left 0.3s;
}

.sidenavbtn.open {
  left: 0px;
  padding-left: 6px;
  padding-right: 6px;
}

.sidenavbtn.close {
  left: 240px;
  padding-left: 6px;
  padding-right: 6px;
}

.sidenavbtn:hover {
  padding-left: 12px;
  padding-right: 12px;
}

.side-nav .logo-wrapper {
  height: 90px;
  background-color: #fff;
}

button.inline-link {
  text-transform: none;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  vertical-align: top;
  color: #43a047;
}
button.inline-link:hover {
  text-decoration: underline;
  color: #2e7d32;
}

table.table-xsm th,
table.table-xsm td {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
