.active-pink-2 input[type=text]:focus:not([readonly]) {
  border-bottom: 1px solid #f48fb1;
  box-shadow: 0 1px 0 0 #f48fb1;
}
.active-pink input[type=text] {
  border-bottom: 1px solid #f48fb1;
  box-shadow: 0 1px 0 0 #f48fb1;
}
.active-purple-2 input[type=text]:focus:not([readonly]) {
  border-bottom: 1px solid #ce93d8;
  box-shadow: 0 1px 0 0 #ce93d8;
}
.active-purple input[type=text] {
  border-bottom: 1px solid #ce93d8;
  box-shadow: 0 1px 0 0 #ce93d8;
}
.active-cyan-2 input[type=text]:focus:not([readonly]) {
  border-bottom: 1px solid #4dd0e1;
  box-shadow: 0 1px 0 0 #4dd0e1;
}
.active-cyan input[type=text] {
  border-bottom: 1px solid #4dd0e1;
  box-shadow: 0 1px 0 0 #4dd0e1;
}
.active-cyan .fa, .active-cyan-2 .fa {
  color: #4dd0e1 !important;
}
.active-purple .fa, .active-purple-2 .fa {
  color: #ce93d8 !important;
}
.active-pink .fa, .active-pink-2 .fa {
  color: #f48fb1 !important;
}
.active-pink-4 input[type=text]:focus:not([readonly]) {
  border: 1px solid #f48fb1;
  box-shadow: 0 0 0 1px #f48fb1;
}
.active-pink-3 input[type=text] {
  border: 1px solid #f48fb1;
  box-shadow: 0 0 0 1px #f48fb1;
}
.active-purple-4 input[type=text]:focus:not([readonly]) {
  border: 1px solid #ce93d8;
  box-shadow: 0 0 0 1px #ce93d8;
}
.active-purple-3 input[type=text] {
  border: 1px solid #ce93d8;
  box-shadow: 0 0 0 1px #ce93d8;
}
.active-cyan-4 input[type=text]:focus:not([readonly]) {
  border: 1px solid #4dd0e1;
  box-shadow: 0 0 0 1px #4dd0e1;
}
.active-cyan-3 input[type=text] {
  border: 1px solid #4dd0e1;
  box-shadow: 0 0 0 1px #4dd0e1;
}
.active-cyan-3 .fa, .active-cyan-4 .fa {
  color: #4dd0e1;
}
.active-purple-3 .fa, .active-purple-4 .fa {
  color: #ce93d8;
}
.active-pink-3 .fa, .active-pink-4 .fa {
  color: #f48fb1;
}
.input-group.md-form.form-sm.form-1 input{
  border: 1px solid #bdbdbd;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.input-group.md-form.form-sm.form-2 input {
  border: 1px solid #bdbdbd;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.input-group.md-form.form-sm.form-2 input.red-border  {
  border: 1px solid #ef9a9a;
}
.input-group.md-form.form-sm.form-2 input.lime-border  {
  border: 1px solid #cddc39;
}
.input-group.md-form.form-sm.form-2 input.amber-border  {
  border: 1px solid #ffca28;
}
