// Your custom variables
// #50b849 logo green
// #4caf50 MDB green-base
// #4285f4 blue/indigo primary color in ED and MDB color-primary
// #2196f3 MDB blue-base
// #db8f1d signature orange
// #ff9800 MDB orange-base
// #2bbbad teal default color in ED
// #aa66cc mauve secondary color in ED

// canvasjs colorset1 palette
// #4F81BC deep blue / MDB: #0277bd light-blue darken-3 / #1976d2 blue darken-2
// #C0504E blood red / MDB: #c62828 red darken-3
// #9BBB58 lime green / MDB: #9ccc65 light-green lighten-1 / #afb42b lime darken-2
// #23BFAA light teal / MDB: #26a69a teal lighten-1 / #26c6da cyan lighten-1
// #8064A1 purple / MDB: #7e57c2 deep-purple lighten-1
// #4AACC5 dull blue / MDB: #00acc1 cyan darken-1 / #42a5f5 blue lighten-1
// #F79647 orange / MDB: #f57c00 orange darken-2
// #7F6084 mulberry / MDB: #512da8 deep-purple darken-2 (not close)
// #77A033 forest green / MDB: #66bb6a green lighten-1 / #7cb342 light-green darken-1
// #33558B darker blue / MDB: #3b465e mdb-color darken-1 / #303f9f indigo darken-2
// #E59566 deep orange / MDB: #ff8a65 deep-orange lighten-2 / #ef6c00 orange darken-3
// blue: 2 (darken-2, lighten-1)
// light-blue: 1 (darken-3)
// green: 1 (lighten-1)
// light-green: 2 (lighten-1, darken-1)
// lime: 1 (darken-2)
// teal: 1 (lighten-1)
// cyan: 2 (lighten-1, darken-2)
// deep-purple: 1.5 (lighten-1, darken-2)
// orange: 2 (darken-2, darken-3)
// deep-orange: 1 (lighten-2)
// red: 1 (darken-3)
// mdb-color: 1 (darken-1)
// indigo: 1 (darken-2)
// canvasjs colorset2 palette
// #6D78AD
// #51CDA0
// #DF7970
// #4C9CA0
// #AE7D99
// #C9D45C
// #5592AD
// #DF874D
// #52BCA8
// #8E7AA3
// #E3CB64
// #C77B85
// #C39762
// #8DD17E
// #B57952
// #FCC26C

// $default-color:        #00c851;
// $default-color-dark:   #007e33;
// $primary-color:      #aa66cc;
// $primary-color-dark: #9933cc;
// $secondary-color:        #4285f4;
// $secondary-color-dark:   #0d47a1;
// $info-color:           #33b5e5;
// $info-color-dark:      #0099cc;
// $success-color:        #2bbbad;
// $success-color-dark:   #00695c;
// $warning-color:        #ffbb33;
// $warning-color-dark:   #ff8800;
// $danger-color:         #ff3547;
// $danger-color-dark:    #cc0000;
// $link-color:           #4caf50;
// $link-hover-color:     #388e3c;

$default-color:        #43a047;
$default-color-dark:   #2e7d32;
$primary-color:        #1e88e5;
$primary-color-dark:   #1565c0;
$secondary-color:      #673ab7;
$secondary-color-dark: #512da8;
$info-color:           #00acc1;
$info-color-dark:      #00838f;
$success-color:        #00897b;
$success-color-dark:   #00695c;
$warning-color:        #fb8c00;
$warning-color-dark:   #ef6c00;
$danger-color:         #e53935;
$danger-color-dark:    #c62828;

$link-color:           #43a047;
$link-hover-color:     #2e7d32;

$navbar-light-color:                $link-color;
$navbar-light-hover-color:          $link-hover-color;
$navbar-light-active-color:         #1b5e20;
$navbar-light-disabled-color:       #a5d6a7;
$navbar-light-toggler-border-color: #e8f5e9;
$navbar-light-brand-color:          $navbar-light-active-color;
$navbar-light-brand-hover-color:    $navbar-light-active-color;
$navbar-light-bg-active-color:      #fff;

$roboto-font-path: "~mdbreact/dist/font/roboto/";
$image-path: "~mdbreact/dist/img";
@import '~mdbreact/dist/scss/mdb-pro.scss';
