body {
  background: #ededed;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3f729b;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1c2331;
}

.btn.btn-sm.indigo:hover {
  color: #fff;
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}

#categories .nav-link {
  position: absolute;
  bottom: 0;
  width: 66%;
}

#categories > .col-md-4.mb-5 {
  margin-bottom: 8rem !important;
}
